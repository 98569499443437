/* import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue' */

'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
