<template>
  <v-container id="HomeView" class="pa-0 ma-0">
    <v-container class="pa-0 ma-0">
        <div v-if="!$auth.isAuthenticated">
          <p></p>
          <h2 style="color:green;"><b>Geosearch Shale wells</b></h2>
          <h4>within a 5-km (~3-mile) radius</h4>
          <h4><i>Login</i> and right click <i>outside</i> the circle to start exploring.</h4>
          <p></p>
          <div id="msg" style="color:Red;">
                {{ message }}
          </div>
        </div>
        <GmapMap
                :center="center"
                :zoom="12.5"
                map-type-id="roadmap"
                style="width: 100%; height: 700px"
                @click="updateCenter"
                >
                <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"        
                />
                <GmapCircle
                :center="center"
                :radius="5000"
                :options="{fillColor:'green',fillOpacity:0.1}"
                />
            </GmapMap>
            <div v-if="$auth.isAuthenticated">
                
                <p>Center: <b>Latitude</b> {{ center.lat.toFixed(2) }} , <b>Longitude</b> {{ center.lng.toFixed(2) }} </p>

                <div id="msg" style="color:Red;">
                  {{ message }}
                </div>

                <p> </p>
                <!-- only when valid data rows > 1                                                                                                                                                                                                                          header row -->
                <div id="sum" style="color:Green;" v-if = 'markerschart.length > 1'>
                <b> {{ total}} </b>
                </div>
                <p> </p>
                <div id="appTable" v-if = 'markersdata.length > 1'>
                <GChart
                    :settings="{ packages: ['table'] }"
                    type="Table"
                    :data="markersdata"                    
                    :options="tableOptions"
                    ref="gChart"
                />    
                </div>
                <p> </p>
                <!-- only when valid data rows > 1 header row -->
                <div id="appChart" v-if = 'markerschart.length > 1'>
                <GChart
                    type="ColumnChart"
                    :data="markerschart"
                    :options="chartOptions"
                />    
                </div>
                <div id="appHist1" v-if = 'tvdshist.length > 3'>
                <GChart
                    type="Histogram"
                    :data="tvdshist"
                    :options="tvdOptions"
                />
                <p> <b>TVD 3Ps: </b>    
                    <b> P90</b> {{ tvd_stats[0].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},
                    <b> P50</b> {{ tvd_stats[1].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},  
                    <b> P10</b> {{ tvd_stats[2].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} feet</p>  
                <p> <b>Average</b> {{ tvd_stats[3].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} feet
                     from {{ tvdshist.length - 1 }} wells</p>
                </div>
                <div id="appHist2" v-if = 'lateralshist.length > 3'>
                <GChart
                    type="Histogram"
                    :data="lateralshist"
                    :options="lateralOptions"
                />  
                <p> <b>Lateral 3Ps: </b>    
                    <b> P90</b> {{ lateral_stats[0].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},
                    <b> P50</b> {{ lateral_stats[1].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},  
                    <b> P10</b> {{ lateral_stats[2].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} feet</p>  
                <p> <b>Average</b> {{ lateral_stats[3].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} feet
                     from {{ lateralshist.length - 1 }} wells</p>

                </div>
                <div id="appHist3" v-if = 'fluid_volumeshist.length > 3'>
                <GChart
                    type="Histogram"
                    :data="fluid_volumeshist"
                    :options="volumeOptions"
                />
                <p> <b>Fluid 3Ps: </b>    
                    <b> P90</b> {{ fluid_stats[0].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},
                    <b> P50</b> {{ fluid_stats[1].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},  
                    <b> P10</b> {{ fluid_stats[2].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} gals</p>  
                <p> <b>Average</b> {{ fluid_stats[3].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} gals
                     from {{ fluid_volumeshist.length - 1 }} wells</p>
                
                <p v-if= 'fluid_stats[3] > fluid_stats[1]'> Comparable to    
                    <b>Median</b>  {{ (fluid_stats[1]/660000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},  
                    <b>Mean</b> {{ (fluid_stats[3]/660000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} Olympic size swimming pools</p>   
                <p v-else> Comparable to
                    Mean {{ (fluid_stats[3]/660000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},
                    <b>Median</b>  {{ (fluid_stats[1]/660000).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} Olympic size swimming pools</p>

                </div>
                <div id="appHist4" v-if = 'proppant_masseshist.length > 3'>
                <GChart
                    type="Histogram"
                    :data="proppant_masseshist"
                    :options="massOptions"
                />
                <p> <b>Proppant 3Ps: </b>    
                    <b> P90</b> {{ proppant_stats[0].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},
                    <b> P50</b> {{ proppant_stats[1].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }},  
                    <b> P10</b> {{ proppant_stats[2].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} lbs</p>  
                <p> <b>Average</b> {{ proppant_stats[3].toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} lbs
                     from {{ proppant_masseshist.length - 1 }} wells</p>
                </div>
                <div id="xplotChart1" v-if = 'lateral_proppant_xplot.length > 3'>
                <GChart
                    type="ScatterChart"
                    :data="lateral_proppant_xplot"
                    :options="scatter_lateral_proppantOptions"
                />
                <p> {{ lateral_proppant_xplot.length - 1 }} wells fit </p> 
                </div>
                <div id="xplotChart2" v-if = 'lateral_fluid_xplot.length > 3'>
                <GChart
                    type="ScatterChart"
                    :data="lateral_fluid_xplot"
                    :options="scatter_lateral_fluidOptions"
                />
                <p> {{ lateral_fluid_xplot.length - 1 }} wells fit</p>  
                </div>
                <div id="xplotChart5" v-if = 'oil_gas_lateral_xplot.length > 3'>
                <GChart
                    type="ScatterChart"
                    :data="oil_gas_lateral_xplot"
                    :options="scatter_oil_gas_lateralOptions"
                />
                <p> {{ oil_gas_lateral_xplot.length - 1 }} oil &amp; gas wells fit </p>
                </div>
                <div id="xplotChart3" v-if = 'oil_lateral_xplot.length > 3 &&
                    oil_lateral_xplot.length > oil_gas_lateral_xplot.length'>
                <GChart
                    type="ScatterChart"
                    :data="oil_lateral_xplot"
                    :options="scatter_oil_lateralOptions"
                />
                <p> {{ oil_lateral_xplot.length - 1 }} oil wells fit</p>
                </div>
                <div id="xplotChart4" v-if = 'gas_lateral_xplot.length > 3 &&
                    gas_lateral_xplot.length > oil_gas_lateral_xplot.length'>
                <GChart
                    type="ScatterChart"
                    :data="gas_lateral_xplot"
                    :options="scatter_gas_lateralOptions"
                />
                <p> {{ gas_lateral_xplot.length - 1 }} gas wells fit</p>
                </div>
                <div v-if = 'markerschart.length > 1 && message.length < 1 &&
                    gas_lateral_xplot.length <= 3 && oil_lateral_xplot.length <= 3 &&
                    oil_gas_lateral_xplot.length <= 3' >
                <p style="color:red;"> <b>Too few wells to derive explainable oil and gas production statistics</b> </p>
                </div>
            </div> 
    </v-container>
  </v-container>
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

import { GChart } from "vue-google-charts";

const percentile = require("percentile");

// return true if in range, otherwise false
function inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
}
// determine number of wells per formation
var occurrence = function (array) {
    "use strict";
    var result = {};
    if (array instanceof Array) {
        array.forEach(function (v, i) {
            if (!result[v]) {
                result[v] = [i];
            } else {
                result[v].push(i);
            }
        });
        Object.keys(result).forEach(function (v) {
            result[v] = result[v].length;
        });
    }
    return result;
};
// functions to get numeric values (array), average and median
var get_numbers = function (array) {
    "use strict";
    var result = {};
    if (array instanceof Array) {
        result = array.map(function(val) {
            return Number(val.slice(1, 2));
          });
    return result;
        }
};
var get_average = function (array) {
    "use strict";
    var cum = 0;
    if (array instanceof Array) {
        array.forEach( arr => {
            cum += arr;
        });
        }
    return cum/array.length
};

export default {
  name: 'HomeView',
  components: {
      GChart
    },
  data() {
    return {
      // default to Midland TX
      center: { lat: 32.00002, lng: -102.07446 },
      message: '',
      total: '',
      markers: [],
      places: [],
      markersdata: [],
      markerschart: [],
      markersfmt: [],
      tvdshist: [],
      lateralshist: [],
      fluid_volumeshist: [],
      proppant_masseshist: [],
      tvd_stats: [],
      lateral_stats: [],
      fluid_stats: [],
      proppant_stats: [],
      lateral_proppant_xplot: [],
      lateral_fluid_xplot: [],
      oil_lateral_xplot: [],
      gas_lateral_xplot: [],
      oil_gas_lateral_xplot: [],
      /* ip_oilhist: [],
      ip_gashist: [],
      eur_oilhist: [],
      eur_gashist: [],
      sfm_oilhist: [],
      sfm_gashist: [],
      esg_oilhist: [],
      esg_gashist: [],
      ip_oilstats: [],
      ip_gasstats: [],
      eur_oilstats: [],
      eur_gasstats: [],
      sfm_oilstats: [],
      sfm_gasstats: [],
      esg_oilstats: [],
      esg_gasstats: [],
      oil_xplot: [],
      gas_xplot: [],
      oil_proppant_xplot: [],
      gas_proppant_xplot: [], */

      currentPlace: null,
      tableOptions: {
        chart: {
          title: "Well Summary",
          subtitle: "API, Name, Basin, County and Formation"
        },
        height: 600,
        width:  900,
      },
      /*tableEvents: {
        select: () => {          
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();          
          const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'
          alert(onSelectionMeaasge);
        }
      }, */
      chartOptions: {
        title: 'Which formations are responsible for the production?',
        hAxis: {
          title: 'Geologic Formation',
          titleTextStyle: {color: '#1b9e77', bold: 'True'}
        },
        vAxis:{title: 'Number of Wells'},
        legend: 'none',
        height: 600,
        colors: ['#1b9e77'],
        minValue: 0,
      },
      // histogram tvd
      tvdOptions: {
        title: 'How deep are these formations?',
        legend: { position: 'none' },
        orientation: 'vertical',
        hAxis: { title: 'Number of Wells' },
        vAxis:{
          title: 'True Vertical Depth (feet)',
          direction: '-1',
          titleTextStyle: {color: '#9b7653', bold: 'True'}
        },
        height: 600,
        colors: ['#9b7653'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },  /* separataion between bars */
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          /* minValue: 0,
          maxValue: 500, */
          hideBucketItems: 'false'
        }
      },
      // histogram lateral
      lateralOptions: {
        title: 'Distribution of Well Lateral Lengths',
        legend: { position: 'none' },
        hAxis: {
          title: 'Horizontal Length (feet)',
          titleTextStyle: { color: '#696969', bold: 'True'}
        },
        vAxis:{title: 'Number of Wells'},
        /* legend: {
          position: 'bottom',
          alignment: 'start'
        }, */
        height: 600,
        colors: ['#696969'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },  /* separataion between bars */
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          /* minValue: 0,
          maxValue: 500, */
          hideBucketItems: 'false'
        }
      },
      // histogram fluid volume
      volumeOptions: {
        title: 'Distribution of First-Completion Fluid Volume',
        legend: { position: 'none' },
        hAxis: {
          title: 'Fluid Volume (gallons)',
          titleTextStyle: {color: '#1CA3EC', bold: 'True'}
        },
        vAxis:{title: 'Number of Wells'},
        /* legend: {
          position: 'bottom',
          alignment: 'start'
        }, */
        height: 600,
        colors: ['#1CA3EC'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },  /* separataion between bars */
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          /* minValue: 0,
          maxValue: 500, */
          hideBucketItems: 'false'
        }
      },
      // histogram proppant mass
      massOptions: {
        title: 'Distribution of First-Completion Proppant Mass',
        legend: { position: 'none' },
        hAxis: {
          title: 'Proppant Mass (pounds)',
          titleTextStyle: { color: '#cdab81', bold: 'True' }
        },
        vAxis:{title: 'Number of Wells'},
        /* legend: {
          position: 'bottom',
          alignment: 'start'
        }, */
        height: 600,
        colors: ['#cdab81'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },  /* separataion between bars */
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          /* minValue: 0,
          maxValue: 500, */
          hideBucketItems: 'false'
        }
      },
      // crossplot fluid v lateral
      scatter_lateral_fluidOptions: {
        title: 'Simple Proxy Model: Linear fit of Fluid Volume versus Lateral Length',
        hAxis: {title: 'Horizontal Length (feet)'},
        vAxis: {title: 'First-Completion Fluid Volume (gallons)'},
        // legend: 'none',
        height: 600,
        colors: ['#1CA3EC'],
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
      // crossplot proppant v lateral
      scatter_lateral_proppantOptions: {
        title: 'Simple Proxy Model: Linear fit of Proppants Mass versus Lateral Length',
        hAxis: {title: 'Horizontal Length (feet)'},
        vAxis: {title: 'First-Completion Proppant Mass (pounds)'},
        // legend: 'none',
        height: 600,
        colors: ['#cdab81'],
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.66,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
      // crossplot EUR oil v lateral
      scatter_oil_lateralOptions: {
        title: 'Simple Proxy Model: Linear fit of EUR Oil versus Lateral Length',
        hAxis: {title: 'Horizontal Length (feet)'},
        vAxis: {title: 'EUR Oil Volume (bbls)'},
        // legend: 'none',
        height: 600,
        colors: ['#1b9e77'],
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
      // crossplot EUR gas v lateral
      scatter_gas_lateralOptions: {
        title: 'Simple Proxy Model: Linear fit of EUR Gas versus Lateral Length',
        hAxis: {title: 'Horizontal Length (feet)'},
        vAxis: {title: 'EUR Gas Volume (Mcf)'},
        // legend: 'none',
        height: 600,
        colors: ['#FF0000'],
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
      // dual crossplot EUR oil, EUR gas v lateral
      scatter_oil_gas_lateralOptions: {
        series: {
            0: {targetAxisIndex: 0},
            1: {targetAxisIndex: 1}
          },
        title: 'Dual Proxy Model: Linear fit of Dual Oil & Gas Wells versus Lateral Length',
        hAxis: {title: 'Horizontal Length (feet)', titleTextStyle: {bold: 'True'},
                  textStyle: {bold: 'True'} },
        // vAxis: {title: 'EUR Gas Volume (Mcf)'},
        vAxes: {
            // Adds titles to each axis.
            0: {title: 'EUR Oil Volume (bbls)', titleTextStyle: {color: '#1b9e77'},
                  textStyle: {color: '#1b9e77'} },
            1: {title: 'EUR Gas Volume (Mcf)',  titleTextStyle: {color: '#FF0000'},
                  textStyle: {color: '#FF0000'} }
          },
        // legend: 'none',
        height: 600,
        colors: ['#1b9e77', '#FF0000'],
        dataOpacity: '0.5',
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        }, 1: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0 and 1.
      }
    }
  },
  created () {
      bus.$on('authenticated', async (user) => {
        this.$store.commit('setUser', user)
        console.log('User signed in: ', this.$store.getters.getUser)
      })
    },
  // Learn more about this component at https://www.npmjs.com/package/vue2-google-maps
  methods: {
    async updateCenter(event) {
      // Remove existing markers
      this.markers = [];
      this.markersdata = [];
      this.markerschart = [];
      this.markersfmt = [];
      this.tvdshist = [];
      this.lateralshist = [];
      this.fluid_volumeshist = [];
      this.proppant_masseshist = [];
      this.tvd_stats = [];
      this.lateral_stats = [];
      this.fluid_stats = [];
      this.proppant_stats = [];
      this.lateral_proppant_xplot = [];
      this.lateral_fluid_xplot = [];
      this.oil_lateral_xplot = [];
      this.gas_lateral_xplot = [];
      this.oil_gas_lateral_xplot = [];
      this.message = '';
      this.total = '';
      /* Arps analytics
      this.oil_xplot = [];
      this.gas_xplot = [];
      this.oil_proppant_xplot = [];
      this.gas_proppant_xplot = [];
      this.ip_oilhist  = [],
      this.ip_gashist  = [],
      this.eur_oilhist = [],
      this.eur_gashist = [],
      this.sfm_oilhist = [];
      this.sfm_gashist = [];
      this.esg_oilhist = [];
      this.esg_gashist = [];
      this.ip_oilstats  = [],
      this.ip_gasstats  = [],
      this.eur_oilstats = [],
      this.eur_gasstats = [],
      this.sfm_oilstats = [];
      this.sfm_gasstats = [];
      this.esg_oilstats = [];
      this.esg_gasstats = []; */

      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      // min latitude set at the southern tip of Florida
      if (!(inRange(this.center.lat, 25.12, 49.38)
        && inRange(this.center.lng, -124.77, -66.95))) {
          this.message = 'Location falls outside the US Lower 48! Please try again.'
        } else { 

        try {
          "use strict";
          const results = await axios({
            method: 'post',
            url: 'https://beoum955l8.execute-api.us-east-1.amazonaws.com/prod/',
            data: {
              lat: this.center.lat,
              lng: this.center.lng
            }
          })
          // setup header for table view
          const markerdata = [
            { type: "string", label: "API", id: "api" },
            { type: "string", label: "Well Name", id: "well" },
            { type: "string", label: "Operator", id: "operator" },
            { type: "string", label: "Basin", id: "basin" },
            { type: "string", label: "Formation", id: "formation" },
            { type: "string", label: "Field", id: "field" },
            { type: "string", label: "County", id: "county" }
            ];
            // console.log(markerdata);
            this.markersdata.push(markerdata);
          
            // this.markerschart.push(['Formation', 'Count', { role: 'style' }]);
            // histogram headers for tvd, lateral, fluid volume and proppant mass
            this.markerschart.push(['Formation', 'Count']);
            // histogram plots
            this.tvdshist.push(['Formation', 'Depth']);
            this.lateralshist.push(['Formation', 'Lateral']);
            this.fluid_volumeshist.push(['Formation', 'Volume']);
            this.proppant_masseshist.push(['Formation', 'Mass']);
            // scatter plots
            this.lateral_proppant_xplot.push(['Lateral', 'Proppant']);
            this.lateral_fluid_xplot.push(['Lateral', 'Fluid']);
            this.oil_lateral_xplot.push(['Lateral', 'EUR_Oil']);
            this.gas_lateral_xplot.push(['Lateral', 'EUR_Gas']);
            this.oil_gas_lateral_xplot.push(['Lateral', 'EUR_Oil', 'EUR_Gas']);

            /* Arps analytics
            this.ip_oilhist.push(['Formation', 'IP_Oil']);
            this.ip_gashist.push(['Formation', 'IP_Gas']);
            this.eur_oilhist.push(['Formation', 'EUR_Oil']);
            this.eur_gashist.push(['Formation', 'EUR_Gas']);
            this.sfm_oilhist.push(['Formation', 'SFM_Oil']);
            this.sfm_gashist.push(['Formation', 'SFM_Gas']);
            this.esg_oilhist.push(['Formation', 'ESG_Oil']);
            this.esg_gashist.push(['Formation', 'ESG_Gas']); */
            //
            /* this.oil_xplot.push(['IP_Oil', 'EUR_Oil']);
            this.gas_xplot.push(['IP_Gas', 'EUR_Gas']);
            this.oil_proppant_xplot.push(['Proppant', 'IP_Oil']);
            this.gas_proppant_xplot.push(['Proppant', 'IP_Gas']); */

          results.data.map((location) => {
            const point = JSON.parse(location.geoJson.S)
            const marker = {
              lat: point.coordinates[1],
              lng: point.coordinates[0],
              api: location.api.S,
              well: location.well.S,
              operator: location.operator.S,
              basin: location.basin.S,
              formation: location.formation.S,
              field: location.field.S,
              county: location.county.S,
              tvd: location.tvd.N,
              lateral:location.lateral_length.N,
              fluid_volume: location.first_completion_fluid_volume.N,
              proppant_mass: location.first_completion_proppant_mass.N,
              ip_oil: location.ip3_oil.N,
              ip_gas: location.ip3_gas.N,
              eur_oil: location.eur_oil.N,
              eur_gas: location.eur_gas.N
            }
            this.markers.push({ position: marker });
            // extact attributes for table
            this.markersdata.push([marker.api, marker.well, marker.operator,
                                  marker.basin, marker.formation, marker.field, marker.county]);
            // label only does not work for histogram so use occurrence to count 
            this.markersfmt.push(marker.formation);
            // extract tvd histogram
            if (marker.tvd > 1000) this.tvdshist.push([marker.formation, marker.tvd]);
            // extract lateral histogram
            if (marker.lateral > 800) this.lateralshist.push([marker.formation, marker.lateral]);
            // extract fluid volume histogram
            if (marker.fluid_volume > 400000)
                this.fluid_volumeshist.push([marker.formation, marker.fluid_volume]);
            // extract proppant mass histogram
            if (marker.proppant_mass > 500000)
                this.proppant_masseshist.push([marker.formation, marker.proppant_mass]);
            // extract proppant mass vs lateral scatter plot
            if (marker.lateral > 800 && marker.proppant_mass > 500000)
               this.lateral_proppant_xplot.push([parseFloat(marker.lateral), parseFloat(marker.proppant_mass)]);
            // extract fluid volumes vs lateral scatter plot
            if (marker.lateral > 800 && marker.fluid_volume > 400000)
               this.lateral_fluid_xplot.push([parseFloat(marker.lateral), parseFloat(marker.fluid_volume)]);  
            // extract EUR vs lateral scatter plots
            // extract IPs vs EURs AND parse as float; otherwise, get error of string
            if (marker.ip_oil > 0 && marker.eur_oil > 0 && marker.lateral > 800)
               this.oil_lateral_xplot.push([parseFloat(marker.lateral), parseFloat(marker.eur_oil)]);
            if (marker.ip_gas > 0 && marker.eur_gas > 0 && marker.lateral > 800)
               this.gas_lateral_xplot.push([parseFloat(marker.lateral), parseFloat(marker.eur_gas)]);
            if (marker.eur_oil > 0 && marker.eur_gas > 0 && marker.lateral > 800)
               this.oil_gas_lateral_xplot.push([parseFloat(marker.lateral), parseFloat(marker.eur_oil), parseFloat(marker.eur_gas)]);
          })
          // if no markers send message
          if (this.markers.length == 0) {
            this.message = 'No shale wells cluster found. Click another location.'
          } else {
            this.total = 'Vue ' + this.markers.length +  ' shale wells.';
            if (this.markers.length == 1) this.total = 'Vue one shale well.'
          // extract number of occurrence
          // console.log(occurrence(this.markersfmt));
            var entries = Object.entries(occurrence(this.markersfmt));
            entries.sort();
            // console.log(entries);
            var i;
            var len = entries.length;
            for ( i = 0; i < len; i++) {
              this.markerschart.push(entries[i]);
            }
            // generate statistics - averagea, P90, P50, P10
            this.tvd_stats = percentile ([10, 50, 90], get_numbers(this.tvdshist.slice(1)));
            this.tvd_stats[3] = get_average(get_numbers(this.tvdshist.slice(1)));
            this.lateral_stats = percentile ([10, 50, 90], get_numbers(this.lateralshist.slice(1)));
            this.lateral_stats[3] = get_average(get_numbers(this.lateralshist.slice(1)));
            this.fluid_stats = percentile ([10, 50, 90], get_numbers(this.fluid_volumeshist.slice(1)));
            this.fluid_stats[3] = get_average(get_numbers(this.fluid_volumeshist.slice(1)));
            this.proppant_stats = percentile ([10, 50, 90], get_numbers(this.proppant_masseshist.slice(1)));
            this.proppant_stats[3] = get_average(get_numbers(this.proppant_masseshist.slice(1)));
            /* console.log('IP Oil 3Ps + Mean');
            console.log(this.fluid_stats); */
            
          }
        }
        catch (err) {
        // eslint-disable-next-line
        console.err('Error: ', err)
        }
      }
    }
  }  
}
</script>