<template>
  <v-app>
    <!-- // App bar color light-green darken-2  dark -->
    <v-app-bar app color="white">
      <!-- Logo -->
      <div class="d-flex align-center">
        <v-img
          alt="Shaleforce"
          class="shrink mr-2"
          contain
          src="./assets/Shaleforce_logo.gif"
          transition="scale-transition"
          width="210" 
          style="top: 1px; left: -20px;"
          display=: block
        />
      </div>

      <v-spacer></v-spacer>

      <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <div v-if="!$auth.loading">
        <!-- show login when not authenticated -->
        <v-btn text v-if="!$auth.isAuthenticated" @click="login">
          <span class="mr-2">Log in</span>
          <v-icon>mdi-checkbox-marked-circle</v-icon>
        </v-btn>
        <!-- show logout when authenticated -->
        <v-btn text v-if="$auth.isAuthenticated" @click="logout">
          <span class="mr-2">Log out</span>
          <v-icon>mdi-account-arrow-right</v-icon>
        </v-btn>
      </div>

    </v-app-bar>

    <!-- Main content -->
    <v-main>
      <v-container class="pa-0 ma-0">
        <!-- Inject view from current path here -->
        <router-view v-if="authReady">
        </router-view>
      </v-container>
    </v-main>
    <v-footer
      color="light-green darken-2"
      dark
      padless
    >
    <v-row
      justify="center"
      no-gutters
    >
    <!-- dialog buttons in a row -->
      <v-btn
        color="white"
        text
        rounded
        class="mr-2"
        @click="dialog_tips = true"
      >
        Tips
      </v-btn>
      <v-btn
        color="white"
        text
        rounded
        class="mr-2"
        @click="dialog_privacy = true"
      >
        Privacy
      </v-btn>
      <v-btn
        color="white"
        text
        rounded
        class="mr-2"
        @click="dialog_terms = true"
      >
        Terms
      </v-btn>
      <v-btn
        color="white"
        text
        rounded
        class="mr-2"
        @click="dialog_contact = true"
      >
        Contact Us
      </v-btn>
      <v-dialog
        v-model="dialog_tips"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Getting Started
          </v-card-title>
          <v-card-subtitle>
            <p></p>
            <p><b>First time?</b> Warm up with one <i>click</i> in the Lower 48, near:</p>
          </v-card-subtitle>
          <v-card-text>
            <p><b>Midland International Airport</b>, Texas,</p>
            <p>City of <b>Williston</b>, North Dakota, or</p>
            <p><b>Washington</b> (southwest of Pittsburgh), Pennsylvania</p>
            <p><a href="https://www.youtube.com/watch?v=PwuLmSM-zW8&t=24s">Getting Started Demo</a></p>
            <p style="color:green;"><b>Log in</b>: authenticated to vue table, charts and proxy models.</p>
            <p style="color:green;">Next, share your <i>geosearch</i> stories!</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="dialog_tips = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog_privacy"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Privacy Policy
          </v-card-title>
          <v-card-text>
            Protecting your private information is our priority. By using the vue-shale-wells app, you consent to the data practices described here.
            vue-shale-wells may collect personally identifiable information, e.g., name and e-mail address.
            vue-shale-wells may use your personally identifiable information to inform you of future products or services, and conduct research and surveys on current services or potential new product offering.
            <u>vue-shale-wells does not sell, rent or lease its customer lists to third parties</u>.
            vue-shale-wells will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to:
            (a) conform to the edicts of the law or comply with legal process served on vue-shale-wells;
            (b) protect and defend the rights or property of vue-shale-wells;
            (c) protect the personal safety of users of vue-shale-wells, or the public.

          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="dialog_privacy = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog_terms"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Terms and Conditions
          </v-card-title>
          <v-card-text>
            Agreement between User and https://www.vue-shale-wells.com.
            <p>The https://www.vue-shale-wells.com website (the "Site") is comprised of single-page-app and components.
            Using vue-shale-wells, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your device, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity.
            You acknowledge that vue-shale-wells is not responsible for third party access to your account that results from theft or misappropriation of your account. vue-shale-wells reserves the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

            <p><u>No Unlawful or Prohibited Use/Intellectual Property</u></p>
            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use https://www.vue-shale-wells.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to vue-shale-wells that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.

            All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of vue-shale-wells or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.

            You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. vue-shale-wells content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of vue-shale-wells and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of vue-shale-wells except as expressly authorized by these Terms.</p>

          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="dialog_terms = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog_contact"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Contact Us
          </v-card-title>
          <v-card-text>
            support@shaleforce.com
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="dialog_contact = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </v-footer>
    <v-footer
    color="light-green darken-2"
    padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          x-small
          color="white"
          text
          disabled
        >
        </v-btn>
        <v-col
          class="light-green darken-2 text-center white--text"
        >
          <small> Copyright &copy; {{  new Date().getFullYear() }} <strong> Shaleforce LLC</strong>. All rights reserved. </small>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { bus } from '@/main';

export default {
  name: 'App',
  data: function () {
    return {
      authReady: false,
      dialog_tips: false,
      dialog_privacy: false,
      dialog_terms: false,
      dialog_contact: false
    }
  },
  created () {
    bus.$on('loaded', async () => {
      console.log('Auth loaded')
      this.$store.commit('setInitialized', true)      
      this.authReady = true
    })
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>

// style to keep the table and message centered

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>